import identity from 'lodash-es/identity';
import noop from 'lodash-es/noop';
import React, {
  lazy,
  Suspense,
} from 'react';
import ReactDOM from 'react-dom';
import {
  connect,
  MapDispatchToProps,
  MapStateToProps,
} from 'react-redux';
import Select from 'react-select';
import {
  AnyAction,
  Dispatch,
} from 'redux';
import styled from 'styled-components';
import {
  postMessageToMergeWorker,
} from '../../getConfiguredStore';
import {
  ProductClass,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {categoryMapHS, categoryMapSITC, getHiddenCategories} from '../../network/Utils';
import {
  showDataNotes,
  showExports,
  showShare,
} from '../../overlay/actions';
import resizeMeasurementListener, {
  IListener,
} from '../../ResizeMeasurementListener';
import {
  IRootState,
} from '../../rootState';
import ComplexityGraphTitle from '../../sharedComponents/ComplexityGraphTitle';
import {
  DataIssueType,
} from '../../sharedComponents/dataNotes/Utils';
import ErrorOverlay from '../../sharedComponents/GraphError';
import Loading from '../../sharedComponents/GraphLoading';
import HighlightDropdown from '../../sharedComponents/HighlightDropdown';
import Share, {
  DataNotesProps,
  TutorialModalProps,
} from '../../sharedComponents/newGraphShare';
import TriggerDataDownload from '../../sharedComponents/newGraphShare/TriggerDataDownload';
import RadioSelector from '../../sharedComponents/radioSelector';
import YearSelector, {
  TimelineType,
} from '../../sharedComponents/timeline';
import {
  fetchIfNeeded as fetchCountryMetadataIfNeeded,
  getDataSelector as getMainThreadCountryMetadataSelector,
} from '../../sharedData/newCountryMetadata';
import GraphTotal from '../../tree/graphTotal/GraphTotal';
import {
  IDropdownOption,
  ILoadable,
  LoadableStatus,
  ProductMetadatumLevel as Level,
} from '../../Utils';
import ChartShownTitle from '../../viz/ChartShownTitle';
import determineNewGraphStatus from '../../viz/determineNewGraphStatus';
import {
  RightUpperControlContainer,
} from '../../viz/TwoColumnVizControlsGrid';
import {
  GraphStatus as GraphStatusBase,
  GraphStatusCode,
  IGraphDispatchProps,
  productClassChoices,
  UpdateType,
  VizType,
} from '../../viz/Utils';
import {
  CategorySelectorContainer,
  ChartContainer,
  ChartHeader,
  HighlightContainer,
  SpinnerContainer,
  TooltipsContainer,
  vizGridZIndices,
  YearSelectorContainerWithoutPlayButton,
} from '../../viz/VizGrid';
import { vizSettingsPortalId } from '../../viz/VizSettings';
import {
  IMetadatum as ICountryMetadatum,
} from '../../workerStore/fetchedData/countryMetadata';
import {
  fetchIfNeeded as fetchMainDataIfNeeded,
} from '../../workerStore/fetchedData/countryProductYearByCountry';
import {
  fetchIfNeeded as fetchLayoutIfNeeded,
} from '../../workerStore/fetchedData/layoutData';
import {
  fetchIfNeeded as fetchLevelMappingIfNeeded,
  MappingType,
} from '../../workerStore/fetchedData/productLevelMapping';
import {
  fetchIfNeeded as fetchMetadataIfNeeded,
} from '../../workerStore/fetchedData/productMetadata';
import {
  fetchIfNeeded as fetch_PY_IfNeeded,
} from '../../workerStore/fetchedData/productYear';
import {
  getHashInputFromRoutingAndUIState,
  IUIState,
  MergedData,
} from '../../workerStore/rings/getReducer';
import {
  ErrorCode,
  IComputationOutput,
} from '../../workerStore/rings/Utils';
import Chart from '../chart';
import {
  getMergedData,
  getUIState,
  getUpdateType,
  reset,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
} from '../reducer';
import DetailOverlayContainer from './DetailOverlayContainer';
import NodeLabels from './NodeLabels';
import TooltipContainer from './TooltipContainer';

const ProductCategorySelector = lazy(() => import(
  /* tslint:disable-next-line:trailing-comma*/
  /* webpackChunkName: "productClassSelector" */ '../../sharedComponents/categorySelector/ProductCategorySelector'
));

const NodeLabelsContainer = styled(ChartContainer)`
  pointer-events: none;
  z-index: ${vizGridZIndices.ringsNodeLabels};
`;

const Dropdown = styled(Select)`
  width: 100%;
`;

const getNextGraphStatus = (
    nextMergedData: MergedData, prevGraphStatus: GraphStatus): GraphStatus => {

  let nextGraphStatus: GraphStatus;
  if (nextMergedData.hasError === true) {
    nextGraphStatus = {
      status: GraphStatusCode.Fail,
      value: nextMergedData.errorCode,
      loadableStatus: LoadableStatus.NotPresent,
    };
  } else {

    nextGraphStatus = determineNewGraphStatus<IComputationOutput, ErrorCode>(
      ErrorCode.NoData, prevGraphStatus, nextMergedData.value,
    );
  }
  return nextGraphStatus;
};
const getStateUpdater = (
      // Note: `unlaggedStateTransformer` is the state transformer function
      // usually passed into `setState` where we perform any change in "unlagged
      // state" here e.g. set IDs of highlighted products or products with
      // detailed overlay:
      unlaggedStateTransformer: (prevState: IUnlaggedState) => IUnlaggedState,
      // Default to `this.props` because most of the time, `updateState` is used
      // in place of `setState` and as such, `nextProps` haven't changed.
      nextProps: IProps,
    ) =>
      (prevState: IState): IState => {

  const newUnlaggedState = unlaggedStateTransformer(prevState);

  const graphStatus = getNextGraphStatus(nextProps.mergedData, prevState.graphStatus);
  const newState = {...newUnlaggedState, graphStatus};
  return newState as IState;
};

export type GraphStatus = GraphStatusBase<IComputationOutput, ErrorCode>;

export interface IOwnProps {
  // Callbacks:
  onYearChange: (year: number) => void;
  onProductClassChange: (value: ProductClass) => void;

  // props from routing:
  productClass: ProductClass;
  year: number;
  country: number | undefined;
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

interface IStateProps extends IUIState {
  // Local state stored in redux store:
  centerNode: number;

  // Computed graph data:
  mergedData: MergedData;
  updateType: UpdateType;

  countryMetadata: ILoadable<Map<number, ICountryMetadatum>>;
}

type IProps = IOwnProps & IStateProps & IGraphDispatchProps & TutorialModalProps;

interface IUnlaggedState {
  detailed: number | undefined;
  hovered: number | undefined;

  // State mirrored from props:
  mirrored: {
    country: IProps['country'];
    year: IProps['year'];
    productClass: IProps['productClass'];
    mergedData: IProps['mergedData'];
  };
}

interface IState extends IUnlaggedState {
  graphStatus: GraphStatus;
  showDataDownload: boolean;
  graphTitle: string;
}

class Rings extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const unlaggedInitialState: IUnlaggedState = {
      detailed: undefined,
      hovered: undefined,

      mirrored: {
        country: props.country,
        year: props.year,
        productClass: props.productClass,
        mergedData: props.mergedData,
      },
    };

    const graphStatus = getNextGraphStatus(props.mergedData, {
      status: GraphStatusCode.Initial,
      loadableStatus: LoadableStatus.Initial,
    });

    this.state = {
      ...unlaggedInitialState,
      graphStatus,
      showDataDownload: false,
      graphTitle: '',
    };
  }

  //#region setState related methods

  // Note: we disable `setState` because using `setState` directly will bypass
  // `updateState`, causing the UI controls to not work correctly e.g.
  // select/unselect categories will not cause the tree map to add/remove those
  // categories even though the category selector will still
  // highlight/unhighlight the icons of those categories:
  setState() {
    throw new Error('Do not call `setState` directly in this component. Use `updateState` instead.');
  }

  private updateState(
      // Note: `unlaggedStateTransformer` is the state transformer function
      // usually passed into `setState` where we perform any change in "unlagged
      // state" here e.g. set IDs of highlighted products or products with
      // detailed overlay:
      unlaggedStateTransformer: (prevState: IUnlaggedState) => IUnlaggedState,
      // Default to `this.props` because most of the time, `updateState` is used
      // in place of `setState` and as such, `nextProps` haven't changed.
      nextProps: IProps = this.props): void {

    // Note: have to call `setState` on `super` because we disable `setState` on
    // this instance;
    super.setState(getStateUpdater(unlaggedStateTransformer, nextProps));
  }
  //#endregion setState-related methods

  private fetchData(props: IProps) {
    const {dispatchToWorkerStore, productClass, country} = props;
    dispatchToWorkerStore(fetchLayoutIfNeeded({productClass}));
    dispatchToWorkerStore(fetchMetadataIfNeeded({productClass}));
    dispatchToWorkerStore(fetchLevelMappingIfNeeded({productClass, mappingType: MappingType.Hi2Low}));
    dispatchToWorkerStore(fetch_PY_IfNeeded({productClass}));
    if (country !== undefined) {
      dispatchToWorkerStore(fetchMainDataIfNeeded({
        productClass, country, level: Level.fourDigit,
      }));
    }
  }

  //#region lifecycle methods:
  componentDidMount() {
    // Register "resizer" method:
    resizeMeasurementListener.addListener(this.resizeListener);

    const {
      dispatchToBothStores, dispatchToWorkerStore, dispatchToMainStore,
      productClass, year, country, tradeDirection, tradeFlow,
    } = this.props;

    dispatchToWorkerStore(startSubscribing());
    dispatchToMainStore(fetchCountryMetadataIfNeeded({}));
    this.fetchData(this.props);
    dispatchToBothStores(
      updateInputFromURLRouting({productClass, year, country, tradeDirection, tradeFlow}, UpdateType.Hard),
    );
  }

  componentWillUnmount() {
    const {dispatchToBothStores, dispatchToWorkerStore} = this.props;
    // Clean up the UI state and derived data in the redux store:
    // and stop computing derived data:
    dispatchToWorkerStore(stopSubscribing());
    dispatchToBothStores(reset());
    // Unregister "resizer" method:
    resizeMeasurementListener.removeListener(this.resizeListener);
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
    const mirrored = {
      country: nextProps.country,
      year: nextProps.year,
      productClass: nextProps.productClass,
      mergedData: nextProps.mergedData,
    };
    // Reset certain UI state when other UI state changes:
    const needToReset = (nextProps.productClass !== prevState.mirrored.productClass) ||
                          (nextProps.mergedData.hasError !== prevState.mirrored.mergedData.hasError);
    let highlightUpdater: (prevState: IUnlaggedState) => IUnlaggedState;
    if (needToReset) {
      // Reset highlighted product if product classification changes:
      highlightUpdater = (inputPrevState: IUnlaggedState): IUnlaggedState => {
        const newDetailed = (inputPrevState.detailed === undefined) ? undefined : nextProps.centerNode;
        return {
          ...inputPrevState,
          detailed: newDetailed,
          hovered: undefined,
        };
      };
    } else {
      highlightUpdater = identity;
    }

    const updatedState = getStateUpdater(highlightUpdater, nextProps)(prevState);

    return {
      ...updatedState,
      mirrored,
    };
  }

  componentDidUpdate(_unused: IProps, prevState: IState) {
    const nextProps = this.props;
    if (nextProps !== prevState.mirrored) {
      if (nextProps.country !== prevState.mirrored.country ||
          nextProps.year !== prevState.mirrored.year ||
          nextProps.productClass !== prevState.mirrored.productClass) {

        this.fetchData(nextProps);
      }

      const {dispatchToBothStores} = nextProps;

      const {
        country, year, productClass, tradeDirection, tradeFlow,
      } = nextProps;
      if (nextProps.country !== prevState.mirrored.country ||
          nextProps.productClass !== prevState.mirrored.productClass) {
        dispatchToBothStores(
          updateInputFromURLRouting({
            country, year, productClass, tradeDirection, tradeFlow,
          }, UpdateType.Hard),
        );
      } else if (nextProps.year !== prevState.mirrored.year) {
        dispatchToBothStores(
          updateInputFromURLRouting({
            country, year, productClass, tradeDirection, tradeFlow,
          }, UpdateType.Soft),
        );
      }

    }
  }
  //#endregion lifecycle methods

  //#region size measurement-related methods

  private chartContainerEl: HTMLElement | null = null;
  private rememberChartContainer = (el: HTMLElement | null) => {
    this.chartContainerEl = el;
    if (el) {
      this.measureDOMLayout();
    }
  }
  private chartRootEl: HTMLElement | null = null;
  private rememberChartRootEl = (el: HTMLElement | null) => this.chartRootEl = el;

  private measureDOMLayout() {
    if (this.chartContainerEl !== null) {
      const {
        width: decimalWidth,
        height: decimalHeight,
      } = this.chartContainerEl.getBoundingClientRect();

      this.props.dispatchToBothStores(updateUIState({
        chartContainerHeight: Math.floor(decimalHeight),
        chartContainerWidth: Math.floor(decimalWidth),
      }, UpdateType.Hard));
    }
  }
  private resizeListener: IListener = this.getResizeListener();
  private getResizeListener(): IListener {
    let decimalWidth: number;
    let decimalHeight: number;
    let originalPositioning: string;

    // Element that we need to remove from regular DOM layout flow so as to allow the
    // chart container to "relax" to its "natural" width:
    let target: HTMLElement | null;

    const before = () => {
      target = this.chartRootEl;
      if (target !== null) {
        // Remember the original value of `position` and `height` so that we can restore them later:
        const computedStyle = window.getComputedStyle(target);
        originalPositioning = computedStyle.position!;
        if (originalPositioning !== null) {
          // ... then take the graph's root elemen out of the flow by setting its `position`
          // to `absolute`. This allows `this.chartContainerEl` to resize to is "natural"
          // width...
          target.style.position = 'absolute';
        }
      }
    };

    const measure = () => {
      const chartContainerEl = this.chartContainerEl;
      if (chartContainerEl !== null) {
        // ... which we measure (excluding the height)...
        const {width, height} = chartContainerEl.getBoundingClientRect();
        decimalWidth = width;
        decimalHeight = height;

      }
    };

    const after = () => {
      target = this.chartRootEl;
      if (target !== null) {
        // ... then restore the `position` and `height` to their original values:
        target.style.position = originalPositioning;
      }

      this.props.dispatchToBothStores(updateUIState({
        chartContainerWidth: Math.floor(decimalWidth),
        chartContainerHeight: Math.floor(decimalHeight),
      }, UpdateType.Hard));
    };

    return {
      before,
      measure,
      after,
    };
  }

  //#endregion size measurement-related methodss

  //#region UI state update methods
  private setCenterNode = (id: number) => {
    // Avoid triggering unnecessary merging operations but still need to update
    // the local `detailed` state so that the user can show/hide the detail
    // overlay:
    if (id !== this.props.centerNode) {
      this.props.dispatchToBothStores(updateUIState({centerNode: id}, UpdateType.Soft));
    }
    this.updateState(
      (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, detailed: id}),
    );
  }
  private onNodeClick = (id: number) => this.setCenterNode(id);

  private setDeselectedCategories = (deselectedCategories: number[]) => this.props.dispatchToBothStores(
    updateUIState({deselectedCategories}, UpdateType.Soft),
  )

  private resetDeselectedCategories = () => this.props.dispatchToBothStores(
    updateUIState({deselectedCategories: []}, UpdateType.Soft),
  )

  private setHover = (hovered: number) => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, hovered}),
  )

  private unsetHover = () => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, hovered: undefined}),
  )

  private updateCenterNode = (input: IDropdownOption) => this.setCenterNode(input.value);

  private resetDetailedProduct = () => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, detailed: undefined}),
  )

  //#endregion UI state update methods

  //#region Exports-related methods
  private onShareClick = () => this.props.dispatchToMainStore(showShare(VizType.Rings));
  private onExportsClick = () => this.props.dispatchToMainStore(showExports(VizType.Rings));
  private onDataDownloadClick = () => {
    this.updateState(
      (state: IState): IState => ({...state, showDataDownload: true}),
    );
  }
  private dismissDataDownload = () => {
    this.updateState(
      (state: IState): IState => ({...state, showDataDownload: false}),
    );
  }
  //#endregion exports-related methods

  private updateGraphTitle = (val: string) => this.updateState(
    prevState => ({...prevState, graphTitle: val}),
  )

  render() {
    const props = this.props;
    const {
      updateType, onYearChange,
      chartContainerHeight, chartContainerWidth,
      deselectedCategories, onProductClassChange,
      centerNode, countryMetadata, dispatchToMainStore,
    } = props;
    const {
      graphStatus, hovered, detailed,
      mirrored: {
        year, productClass, country,
      },
    } = this.state;

    //#region loading spinner
    let loadingSpinner: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Initial ||
        graphStatus.status === GraphStatusCode.LoadingAfterFailure ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {

      if (updateType === UpdateType.Hard) {
        loadingSpinner = <Loading/>;
      } else {
        loadingSpinner = null;
      }
    } else {
      loadingSpinner = null;
    }
    //#endregion loading spinner

    //#region error message
    let errorOverlay: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Fail) {
      const {value} = graphStatus;
      let message: string;
      if (value === ErrorCode.NoData) {
        message = __lexiconText('error.noData');
      } else {
        message = __lexiconText('error.chooseCountry');
      }
      errorOverlay = (
        <ErrorOverlay message={message}/>
      );
    } else {
      errorOverlay = null;
    }
    //#endregion error message

    //#region "total" and rings graph
    let totalElem: JSX.Element | null;
    let ringsGraphElem: JSX.Element | null;
    let dataDownload: JSX.Element | null;

    if (graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {

      const {value: {
        nodes, links, texts, connectionsMap, total, unfilteredTotal,
      }} = graphStatus;
      totalElem = (
        <GraphTotal
          numerator={total}
          denominator={unfilteredTotal}
          totalGoods={unfilteredTotal}
          totalServices={null}
          hiddenCategories={getHiddenCategories(deselectedCategories, productClass)}
          selectedCategories={['product']}
          complexityGraph={true}
        />
      );
      ringsGraphElem = (
        <Chart
          chartContainerHeight={chartContainerHeight} chartContainerWidth={chartContainerWidth}
          hovered={hovered}
          nodes={nodes} links={links} texts={texts} connectionsMap={connectionsMap}
          saveRootEl={this.rememberChartRootEl}
          onNodeClick={this.onNodeClick}
          onMouseEnter={this.setHover}
          onMouseLeave={this.unsetHover}/>
      );
      if (this.state.showDataDownload
          && this.props.mergedData.hasError === false
          && this.props.mergedData.value.status === LoadableStatus.Present
        ) {
        const csvData: object[] = [];
        const categoryMap = productClass === ProductClass.HS ? categoryMapHS : categoryMapSITC;
        const tooltipMap = this.props.mergedData.value.data.tooltipMap;
        Object.keys(tooltipMap).forEach((key) => {
          const {shortLabel: Name, tooltipInfo, color} = tooltipMap[key];
          const targetSector = categoryMap.find(c => c.color === color);
          const dataRows: {[label: string]: any} = {};
          tooltipInfo.forEach(({label, value}) => {
            if (typeof value === 'string' || typeof value === 'number') {
              dataRows[label] = value;
            }
          });
          const Sector = targetSector ? targetSector.name : '';
          csvData.push({Name, ...dataRows, Sector});
        });
        const title = this.state.graphTitle.length ? this.state.graphTitle : 'data';
        dataDownload = (
          <TriggerDataDownload
            data={csvData}
            graphTitle={title}
            closeOverlay={this.dismissDataDownload}
          />
        );
      } else {
        dataDownload = null;
      }
    } else {
      totalElem = (
        <GraphTotal
          numerator={0}
          denominator={0}
          totalGoods={0}
          totalServices={null}
          hiddenCategories={[]}
          selectedCategories={[]}
          complexityGraph={true}
        />
      );
      ringsGraphElem = null;
      dataDownload = null;
    }
    //#endregion "total" and product space

    //#region labels
    let nodeLabels: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {
      const {value: {texts, connectionsMap}} = graphStatus;

      let connectedToHoveredNode: Set<number> | undefined;
      if (hovered === undefined) {
        connectedToHoveredNode = undefined;
      } else {
        // Need this check because while the node animation is going on, the `hovered`
        // state may be triggered for nodes in transit that actually doesn't have
        // any connections:
        const retrieved = connectionsMap[hovered];
        const checkedForUndefined = (retrieved === undefined) ? [] : retrieved;
        connectedToHoveredNode = new Set([...checkedForUndefined, hovered]);

      }
      nodeLabels = (
        <NodeLabels
          chartContainerHeight={chartContainerHeight} chartContainerWidth={chartContainerWidth}
          texts={texts} connectedToHoveredNode={connectedToHoveredNode}
        />
      );
    } else {
      nodeLabels = null;
    }
    //#endregion labels

    //#region year selector
    const yearSelector = (
      <YearSelector
        productClass={productClass} year={year}
        type={TimelineType.SingleYear}
        onYearChange={onYearChange}
      />
    );
    //#endregion year selector

    //#region category selector
    const categorySelector = (
      <Suspense fallback={(<CategorySelectorContainer/>)}>
        <ProductCategorySelector
          deselectedCategories={deselectedCategories}
          productClass={productClass}
          setDeselected={this.setDeselectedCategories}
          resetDeselected={this.resetDeselectedCategories}
          isServicesEnabled={false}
          isServicesNotAvailableForAllYears={false}
        />
      </Suspense>
    );
    //#endregion category selector

    //#region product class selector
    const productClassSelector = (
      <RadioSelector
        tooltipText={__lexiconText('applicationWide.productClassSelector.tooltipText')}
        mainLabel={__lexiconText('applicationWide.productClassSelector.mainLabel')}
        choices={productClassChoices}
        selected={productClass}
        onClick={onProductClassChange}
      />
    );
    //#endregion product class selector

    //#region product highlight dropdown
    let dropdownOptions: IDropdownOption[];
    if (graphStatus.status === GraphStatusCode.Success) {
      ({value: {dropdownOptions}} = graphStatus);
    } else {
      dropdownOptions = [];
    }

    const highlightDropdown = (
      <HighlightDropdown
        label={__lexiconText('applicationWide.highlightDropdown.mainLabel')}
        tooltipText={__lexiconText('applicationWide.highlightDropdown.tooltipText')}
        options={dropdownOptions}
        value={centerNode}
        onChange={this.updateCenterNode}
        isClearable={false}
        DropdownComponent={Dropdown as any}
        DropdownContainerComponent={RightUpperControlContainer}
        vizType={VizType.Rings}
        />
    );
    //#endregion product highlight dropdown

    //#region Hover tooltip
    let hoverTooltip: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Success && hovered !== undefined) {

        const {value: {tooltipMap}} = graphStatus;
        hoverTooltip = (
          <TooltipContainer
            tooltipMap={tooltipMap} hovered={hovered}
            productClass={productClass} chartContainerHeight={chartContainerHeight}
            chartContainerWidth={chartContainerWidth}
          />
        );

    } else {
      hoverTooltip = null;
    }
    //#endregion

    //#region Detail overlay
    let detailOverlay: JSX.Element | null;
    if ((graphStatus.status === GraphStatusCode.Success ||
          graphStatus.status === GraphStatusCode.LoadingAfterSuccess) &&
          detailed !== undefined) {
      const {value: {tooltipMap, nodes}} = graphStatus;
      detailOverlay = (
        <DetailOverlayContainer
          productClass={productClass}
          hideOverlay={this.resetDetailedProduct}
          rememberEl={noop}
          detailed={detailed} tooltipMap={tooltipMap}
          nodes={nodes}
        />
      );
    } else {
      detailOverlay = null;
    }
    //#endregion

    let dataNotesProps: DataNotesProps;
    if (country === undefined || countryMetadata.status !== LoadableStatus.Present) {
      dataNotesProps = {
        isDataNotesWarningActive: false,
        launchDataNotes: () => dispatchToMainStore(
          showDataNotes([]),
        ),
      };
    } else {
      const {data} = countryMetadata;
      const retrievedMetadatum = data.get(country);
      if (retrievedMetadatum === undefined) {
        throw new Error('Cannot find metadatum for country' + country);
      }
      const showCountryNotes = !retrievedMetadatum.is_trusted;
      let isDataNotesWarningActive: boolean, dataNotes: DataIssueType[];
      if (showCountryNotes === true) {
        isDataNotesWarningActive = true;
        dataNotes = [DataIssueType.UnreliableCountry];
      } else {
        isDataNotesWarningActive = false;
        dataNotes = [];
      }
      dataNotesProps = {
        isDataNotesWarningActive,
        launchDataNotes: () => dispatchToMainStore(
          showDataNotes(dataNotes),
        ),
      };
    }

    const launchShare = country === undefined ? null : this.onShareClick;
    const launchExports = country === undefined ? null : this.onExportsClick;

    const share = (
      <Share
        launchShare={launchShare}
        launchExports={launchExports}
        showDataDownload={this.onDataDownloadClick}
        dataNotesProps={dataNotesProps}
        isTutorialModalOpen={this.props.isTutorialModalOpen}
        setIsTutorialModalOpen={this.props.setIsTutorialModalOpen}
        closeDetailOverlay={this.resetDetailedProduct}
      />
    );

    const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

    let vizSettings: React.ReactElement<any> | null;
    if (vizSettingsNodeRef) {
      vizSettings = ReactDOM.createPortal((
        <>
          {productClassSelector}
        </>
      ), vizSettingsNodeRef);
    } else {
      vizSettings = null;
    }

    return (
      <>
        <ChartHeader>
          <ComplexityGraphTitle
            country={country}
            year={year}
            feasibility={false}
            setTitle={(val: string) => this.updateGraphTitle(val)}
          />
          <ChartShownTitle
            text={totalElem}
          />
        </ChartHeader>
        <ChartContainer ref={this.rememberChartContainer}>
          {ringsGraphElem}
        </ChartContainer>
        <NodeLabelsContainer>
          {nodeLabels}
        </NodeLabelsContainer>
        <TooltipsContainer>
          {hoverTooltip}
        </TooltipsContainer>
        {detailOverlay}
        <SpinnerContainer>
          {loadingSpinner}
          {errorOverlay}
        </SpinnerContainer>
        {categorySelector}
        <HighlightContainer>
          {highlightDropdown}
        </HighlightContainer>
        <YearSelectorContainerWithoutPlayButton>
          {yearSelector}
        </YearSelectorContainerWithoutPlayButton>
        {vizSettings}
        {share}
        {dataDownload}
      </>
    );
  }
}

const mapStateToProps: () => MapStateToProps<IStateProps, IOwnProps, IRootState> = () => {
  const getCountryMetadata = getMainThreadCountryMetadataSelector();

  return (rootState: IRootState, ownProps: IOwnProps) => {
    const uiState = getUIState(rootState);
    const {
      chartContainerHeight, chartContainerWidth,
      centerNode, deselectedCategories,
    } = uiState;
    const hashInput = getHashInputFromRoutingAndUIState(ownProps, uiState);
    const mergedData = getMergedData(rootState, hashInput);
    const updateType = getUpdateType(rootState);
    const countryMetadata = getCountryMetadata(rootState, {});
    const result: IStateProps = {
      centerNode, mergedData, updateType,
      chartContainerHeight, chartContainerWidth,
      deselectedCategories,
      countryMetadata,
    };
    return result;
  };
};

const mapDispatchToProps: MapDispatchToProps<IGraphDispatchProps, IOwnProps> =
  (dispatch: Dispatch) => {

    return {
      dispatchToWorkerStore: (action: AnyAction) => postMessageToMergeWorker(action),
      dispatchToMainStore: (action: AnyAction) => dispatch(action),
      dispatchToBothStores: (action: AnyAction) => {
        dispatch(action);
        postMessageToMergeWorker(action);
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Rings);
